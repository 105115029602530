import Logo from '@/assets/logo-dia-svg.svg';
import { faGears, faHome, faMap, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Layout, Menu, MenuProps, theme } from 'antd';
import { relative } from 'path';
import { FunctionComponent, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const { Header, Footer, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('Home', '/', <Icon icon={faHome} />),
  getItem('Map', '/map', <Icon icon={faMap} />),
  getItem('Triggers', '/triggers', <Icon icon={faGears} />),
  getItem('Monitoring', '/monitoring', <Icon icon={faSearch} />),
];

export const BaseLayout: FunctionComponent = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onPageSelect = e => {
    navigate(e.key);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
        <img style={{ width: '75%', margin: '12px 12.5%' }} src={Logo} />
        <Menu theme="dark" defaultSelectedKeys={[location.pathname ?? '/']} onSelect={onPageSelect} mode="inline" items={items} />
      </Sider>
      <Layout className="site-layout">
        <Header style={{ padding: 0, background: colorBgContainer }} />
        <Content style={{ margin: '16px' }}>
          <div style={{ padding: 24, minHeight: '100%', background: colorBgContainer, position: 'relative', overflow: 'hidden' }}>
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>VAA Plots Performance Hub</Footer>
      </Layout>
    </Layout>
  );
};
