import { LatLngExpression } from 'leaflet';
import { FunctionComponent, ReactNode } from 'react';
import styles from './Map.module.scss';

import { MapContainer, TileLayer } from 'react-leaflet';

interface MapProps {
  center: LatLngExpression;
  zoom?: number;
  children?: ReactNode;
}

export const Map: FunctionComponent<MapProps> = ({ center, children, zoom = 18 }) => {
  return (
    <MapContainer className={styles.map} zoom={zoom} center={center} scrollWheelZoom>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {children}
    </MapContainer>
  );
};
