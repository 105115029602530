import { triggerExatrekSync, insertWerkTrekkerCropfield, insertWerkTrekkerOperation } from '@/adapters/worker-adapter';
import { CreateExternalOperationRequest, CreateExternalPlotRequest } from '@/shared/@types/requests';
import { Button, Card, Form, Input, notification, Space } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';
import React, { useMemo } from 'react';
import { CSSProperties, FunctionComponent, useState } from 'react';
import style from '../components/trigger.module.scss';

export const TriggerPage: FunctionComponent = () => {
  const Context = React.createContext({ name: 'Default' });
  const [api, contextHolder] = notification.useNotification();
  const placement: NotificationPlacement = 'top';

  const cardProps: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '16vw',
    minHeight: '120px',
  };

  const onOperationFormFinish = async (values: { brpid: number; externalid: string; data: string; geometry: string }) => {
    const now = new Date();
    const inTenYears = new Date();
    inTenYears.setFullYear(2033);

    const req: CreateExternalOperationRequest = {
      brpId: values.brpid,
      externalId: values.externalid,
      startDate: now,
      endDate: inTenYears,
      // placeholder geometry
      geometry: JSON.parse(values.geometry),
      data: JSON.parse(values.data),
      coordinateSystem: 'EPSG:4326',
    };

    insertWerkTrekkerOperation(req)
      .then(
        () => {
          api.success({ message: 'Succesfully added operation!', placement });
        },
        () => {
          api.error({ message: 'Could not add operation', placement });
        }
      )
      .catch(() => {
        api.error({ message: 'Could not add operation', placement });
      });
  };

  const onPlotFormFinish = (values: { brpid: number; externalid: string; data: string }) => {
    const req: CreateExternalPlotRequest = {
      brpId: values.brpid,
      externalId: values.externalid,
      data: JSON.parse(values.data),
      coordinateSystem: 'EPSG:4326',
    };

    insertWerkTrekkerCropfield(req)
      .then(
        () => {
          api.success({ message: 'Succesfully added plot', placement });
        },
        () => {
          api.error({ message: 'Could not add plot', placement });
        }
      )
      .catch(() => {
        api.error({ message: 'Could not add plot', placement });
      });
  };

  const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);

  return (
    <>
      <Context.Provider value={contextValue}>
        {contextHolder}
        <Space align="start">
          <Card bodyStyle={cardProps} className={style.card} title="Add Operation">
            <Form layout="vertical" autoComplete="off" onFinish={onOperationFormFinish}>
              <Form.Item label="External Id" name="externalid" rules={[{ required: true, message: 'Please input the external Id' }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Geometry" name="geometry" rules={[{ required: true, message: 'Please input a valid geometry' }]}>
                <Input.TextArea
                  style={{ height: '120px' }}
                  placeholder={JSON.stringify({
                    geometry: {
                      type: 'Point',
                      coordinates: [6.515075, 52.007254],
                    },
                  })}
                />
              </Form.Item>
              <Form.Item label="Data" name="data">
                <Input.TextArea style={{ height: '120px' }} placeholder={JSON.stringify({ machineId: 'trekker123', type: 'plowing' })} />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Add Werktrekker Operation
                </Button>
              </Form.Item>
            </Form>
          </Card>
          <Card bodyStyle={cardProps} className={style.card} title="Add Plot">
            <Form layout="vertical" autoComplete="off" onFinish={onPlotFormFinish}>
              <Form.Item label="External Id" name="externalid" rules={[{ required: true, message: 'Please input the external Id' }]}>
                <Input />
              </Form.Item>
              <Form.Item label="BRP Id" name="brpid" rules={[{ required: true, message: 'Please input the BRP Id' }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Data" name="data">
                <Input.TextArea style={{ height: '120px' }} placeholder={JSON.stringify({ crop: 'Grain', soiltype: 'Dirt' })} />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Add Brp Plot
                </Button>
              </Form.Item>
            </Form>
          </Card>
          <Card bodyStyle={cardProps} className={style.card} title="Sync Exatrek Jobs">
            <Button type="primary" onClick={triggerExatrekSync}>
              Trigger Exatrek Job Sync
            </Button>
          </Card>
        </Space>
      </Context.Provider>
    </>
  );
};
