import { MapPage } from '@/modules/map/pages';
import { JaegerPage } from '@/modules/monitoring';
import { TriggerPage } from '@/modules/triggers';
import { Login, ProtectedRoute } from 'modules/auth';
import Home from 'modules/home';
import { FC } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { BaseLayout } from 'shared/components';

export const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

const Routes = () => {
  // .... is auth function
  const routes = useRoutes([
    {
      element: <BaseLayout />,
      children: [
        { element: <Home />, path: '/' },
        { element: <TriggerPage />, path: '/triggers' },
        { element: <MapPage />, path: '/map' },
        { element: <JaegerPage />, path: '/monitoring' },
        {
          path: '/login',
          element: <Login />,
        },
      ],
    },
  ]);
  return routes;
};
