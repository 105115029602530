import { GetFields } from '@/adapters/plot-adapter';
import { Field } from '@/shared/@types/domain';
import { Feature } from 'geojson';
import { Layer } from 'leaflet';
import { FunctionComponent, useEffect, useState } from 'react';
import { GeoJSON } from 'react-leaflet';
import { SetDrawerProps } from '../pages';

export const Fields: FunctionComponent<SetDrawerProps> = ({ setDrawerInfo }) => {
  const [fields, setFields] = useState<Field[]>([]);

  useEffect(() => {
    GetFields({}).then(e => setFields(e));
  }, []);

  return (
    <>
      {fields.map(field => {
        const onFeature = (feature: Feature, layer: Layer) => {
          layer.on({
            click: () => {
              setDrawerInfo(
                <>
                  <h1>Field id: {field.id}</h1>
                </>
              );
            },
          });
        };

        return field ? <GeoJSON onEachFeature={onFeature} pathOptions={{ color: 'red' }} key={field.id} data={field.geometry} /> : <></>;
      })}
    </>
  );
};
