import { getOperationData, getOperations } from '@/adapters/operation-adapter';
import { Operation, OperationData } from '@/shared/@types/domain';
import { FunctionComponent, useEffect, useState } from 'react';
import { GeoJSON } from 'react-leaflet';
import { Feature } from 'geojson';
import { Layer } from 'leaflet';
import { SetDrawerProps } from '../pages';
import { Descriptions, Table } from 'antd';

export const Operations: FunctionComponent<SetDrawerProps> = ({ setDrawerInfo }) => {
  const [operations, setOperations] = useState<Operation[]>([]);
  const [highlighted, setHighlighted] = useState<Operation | null>(null);
  const importantKeys = [
    'MachineName',
    'MachineMake',
    'MachineModel',
    'PrimaryImplementName',
    'DriverUsername',
    'FuelConsumed',
    'HaWorked',
    'KmWorked',
    'DurationWorked',
    'DurationOnField',
    'Start',
    'Stop',
  ];

  useEffect(() => {
    const get = async () => {
      // todo: fix geofilter in backend
      // todo: get seperated
      const available = await getOperations({});
      setOperations(available);
    };

    get();
  }, []);

  return (
    <>
      {operations.map(op => {
        const onFeature = (feature: Feature, layer: Layer) => {
          layer.on({
            mouseover: () => {
              setHighlighted(op);
            },
            mouseout: () => {
              setHighlighted(null);
            },
            click: () => {
              getOperationData(op.id).then(data => {
                const importantData: OperationData[] = [];
                data.forEach(entry => {
                  if (importantKeys.includes(entry.key)) {
                    importantData.push(entry);
                  }
                });

                setDrawerInfo(
                  <>
                    <Descriptions title="Operation Info" bordered>
                      <Descriptions.Item label="Id">{op.id}</Descriptions.Item>
                      <Descriptions.Item label="Start">{op.start?.toString()}</Descriptions.Item>
                      <Descriptions.Item label="End">{op.end?.toString()}</Descriptions.Item>
                    </Descriptions>
                    <br />
                    <Descriptions title="Operation Data Highlighted" bordered>
                      {importantData.map((entry, i) => (
                        <Descriptions.Item key={'highlighted_' + i} label={entry.key}>
                          {entry.value}
                        </Descriptions.Item>
                      ))}
                    </Descriptions>
                    <br />
                    <h3>All Data</h3>
                    <Table
                      pagination={{ pageSize: 5 }}
                      dataSource={data}
                      columns={[
                        { title: 'Key', dataIndex: 'key', key: 'key' },
                        { title: 'Value', dataIndex: 'value', key: 'val' },
                        { title: 'Unit', dataIndex: 'unit', key: 'unit' },
                        { title: 'Type', dataIndex: 'type', key: 'type' },
                      ]}
                    />
                  </>
                );
              });
            },
          });
        };

        return !op.geometry ? (
          <></>
        ) : (
          <GeoJSON pathOptions={{ color: highlighted?.id === op.id ? '#c92d06' : '#0654c9' }} onEachFeature={onFeature} key={op.id} data={op.geometry} />
        );
      })}
    </>
  );
};
