import { FC, Suspense } from 'react';

import { ConfigProvider } from 'antd';

import nlNL from 'antd/es/locale/nl_NL';
import { Provider } from 'jotai';
import { ErrorBoundary } from 'shared/components';

import { AuthWrapper } from '@/modules/auth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import styleVariables from 'styles/_export.module.scss';
import { InitWrapper } from './InitWrapper';
import { AppRouter } from './router';

ConfigProvider.config({
  theme: {
    primaryColor: styleVariables.primaryColor,
  },
});
const queryClient = new QueryClient();
export const App: FC = () => (
  <Suspense fallback={<p>loading</p>}>
    <QueryClientProvider client={queryClient}>
      <Provider>
        <AuthWrapper>
          <InitWrapper>
            <ConfigProvider
              locale={nlNL}
              theme={{
                token: {},
              }}>
              <ErrorBoundary>
                <AppRouter />
              </ErrorBoundary>
            </ConfigProvider>
          </InitWrapper>
        </AuthWrapper>
      </Provider>
    </QueryClientProvider>
  </Suspense>
);
