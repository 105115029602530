import { Map } from '@/shared/components/map';
import { Drawer } from 'antd';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { LayerGroup, LayersControl } from 'react-leaflet';
import { CropFields } from '../model/CropFields';
import { Fields } from '../model/Fields';
import { Operations } from '../model/Operations';

export interface SetDrawerProps {
  setDrawerInfo: (content: ReactNode) => void;
}

export const MapPage: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState<ReactNode>(null);

  const showDrawer = () => {
    if (drawerContent === null) return;
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(showDrawer, [drawerContent]);

  return (
    <>
      <Drawer title="Info" width={'70vw'} placement={'right'} closable={false} onClose={onClose} open={open} key={'info_drawer'}>
        {drawerContent}
      </Drawer>
      <Map center={[52.009077, 6.514249]}>
        <LayersControl position="topright">
          <LayersControl.Overlay checked name="Operations">
            <LayerGroup>
              <Operations setDrawerInfo={setDrawerContent} />
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Fields">
            <LayerGroup>
              <Fields setDrawerInfo={setDrawerContent} />
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="Cropfields">
            <LayerGroup>
              <CropFields setDrawerInfo={setDrawerContent} />
            </LayerGroup>
          </LayersControl.Overlay>
        </LayersControl>
      </Map>
    </>
  );
};
