import { FC } from 'react';
import poah from '@/assets/mienmerk.png';

export const Home: FC = () => {
  return (
    <>
      <h1>Welkom bij de VAA Plots Performance Hub!</h1>
      <p>Dit is een placeholder text voor de landing page</p>
      <img alt="trekker" src={poah} style={{ position: 'absolute', bottom: 0, left: 0, height: 300, opacity: 0.6 }} />
    </>
  );
};
