import { GetCropFields } from '@/adapters/plot-adapter';
import { CropField } from '@/shared/@types/domain';
import { Descriptions } from 'antd';
import { Feature } from 'geojson';
import { Layer } from 'leaflet';
import { FunctionComponent, useEffect, useState } from 'react';
import { GeoJSON } from 'react-leaflet';
import { SetDrawerProps } from '../pages';

export const CropFields: FunctionComponent<SetDrawerProps> = ({ setDrawerInfo }) => {
  const [cropFields, setCropFields] = useState<CropField[]>([]);
  const [highlighted, setHighlighted] = useState<CropField | null>(null);

  useEffect(() => {
    GetCropFields({}).then(e => setCropFields(e));
  }, []);

  return (
    <>
      {cropFields.map(cropField => {
        const onFeature = (feature: Feature, layer: Layer) => {
          layer.on({
            mouseover: () => {
              setHighlighted(cropField);
            },
            mouseout: () => {
              setHighlighted(null);
            },
            click: () => {
              setDrawerInfo(
                <>
                  <Descriptions column={2} title={'Cropfield Info'} bordered>
                    <Descriptions.Item label="Id">{cropField.id}</Descriptions.Item>
                    <Descriptions.Item label="Field Id">{cropField.fieldId}</Descriptions.Item>
                    <Descriptions.Item label="Plot Id">{cropField.plotId}</Descriptions.Item>
                    <Descriptions.Item label="Crop">{cropField.crop === '' ? 'n.v.t.' : cropField.crop}</Descriptions.Item>
                    <Descriptions.Item label="Crop Code">{cropField.cropCode === 0 ? 'n.v.t.' : cropField.cropCode}</Descriptions.Item>
                  </Descriptions>
                </>
              );
            },
          });
        };

        return cropField.geometry ? (
          <GeoJSON
            onEachFeature={onFeature}
            pathOptions={{ color: cropField.id === highlighted?.id ? '#cfd600' : '#135900' }}
            key={cropField.id}
            data={cropField.geometry}
          />
        ) : (
          <></>
        );
      })}
    </>
  );
};
