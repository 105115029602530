import { worker } from '@/shared/model';
import { CropFieldInfo, OperationInfo } from '@/shared/@types/domain';
import { CreateExternalOperationRequest, CreateExternalPlotRequest } from '@/shared/@types/requests';

export const triggerExatrekSync: () => Promise<void> = async () => {
  return worker.post(`/Job/trigger`);
};

export const insertWerkTrekkerOperation: (req: CreateExternalOperationRequest) => Promise<OperationInfo> = async req => {
  return worker.post(`/Operations`, req);
};

export const insertWerkTrekkerCropfield: (req: CreateExternalPlotRequest) => Promise<CropFieldInfo> = async req => {
  return worker.post(`/Plots`, req);
};
