import { Operation, OperationData, OperationInfo, OperationSamplePoint } from '@/shared/@types/domain';
import { FilterRequest, PagedResponse, PageRequest } from '@/shared/@types/requests';
import { api } from '@/shared/model';

export const getAvailableOperations: (pageRequest: PageRequest) => Promise<PagedResponse<OperationInfo>> = async (request: PageRequest) => {
  return await api.post('/Operation/index', request);
};

export const getOperationSamplePoints: (id: string) => Promise<OperationSamplePoint[]> = async (id: string) => {
  return await api.get(`/Operation/${id}/samplePoints`);
};

export const getOperationData: (id: string) => Promise<OperationData[]> = async (id: string) => {
  return await api.get(`/Operation/${id}/data`);
};

export const getOperations: (filterRequest: FilterRequest) => Promise<Operation[]> = async (filterRequest: FilterRequest) => {
  return await api.post(`/Operation/filtered`, filterRequest);
};
