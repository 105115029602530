import { CropField, Field, Plot } from '@/shared/@types/domain';
import { FilterRequest } from '@/shared/@types/requests';
import { api } from '@/shared/model';

export const GetFields: (filterRequest?: FilterRequest) => Promise<Field[]> = async (filterRequest?: FilterRequest) => {
  return api.post(`/Field/filtered`, filterRequest ?? {});
};

export const GetCropFields: (filterRequest?: FilterRequest) => Promise<CropField[]> = async (filterRequest?: FilterRequest) => {
  return api.post(`/CropField/filtered`, filterRequest ?? {});
};

export const GetPlots: (filterRequest?: FilterRequest) => Promise<Plot[]> = async (filterRequest?: FilterRequest) => {
  return api.post(`/Plot/filtered`, filterRequest ?? {});
};
